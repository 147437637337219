<template>
  <v-dialog
    width="454"
    class="close-alert-dialog"
    v-model="isOpen"
    @click:outside="emit('close')"
  >
    <v-card
      class="close-alert"
    >
      <div class="title">
        部分レンダリング作業を終了しますか？
      </div>

      <div class="text">
        {{ `部分レンダリング作業を途中で終了すると、これまでの修正内容は全て失われます。
        この操作は元に戻せません。` }}
      </div>

      <div class="buttons">
        <v-btn
          text="キャンセル"
          @click="emit('close')"
        />
        <v-btn
          text="終了"
          color="primary-light"
          @click="emit('clear')"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
const props = defineProps<{
  isOpen: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'clear'): void
}>()

const isOpen = computed(() => props.isOpen)
</script>

<style lang="scss" scoped>
.close-alert-dialog {
  :deep(.v-overlay__scrim) {
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }

  .close-alert {
    background: #19212c;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      padding: 0;
      font-weight: 700;
      font-size: 16px;
    }

    .text {
      padding: 0;
      font-size: 12px;
      white-space: pre-line;
      line-height: 1.5;
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 16px;

      :deep(.v-btn) {
        flex: 1;
        min-width: 0;
        font-weight: 700;
        font-size: 14px;

        &:first-child {
          background-color: rgb(var(--v-theme-surface));
          color: rgb(var(--v-theme-primary));
        }
      }
    }
  }
}
</style>
